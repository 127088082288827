const requestApi = require("@/lib/request");
// 直播间接口
module.exports = {
    //直播间列表
    roomList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间操作
    roomForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间记录
    roomLogList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-log-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间记录删除
    roomLogDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-log-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间记录删除
    roomChatLogDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-chat-log-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    roomLogExcel(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-log-excel', data, true).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间黑名单列表
    roomBlackList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-black-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //取消黑名单
    roomBlackStatus(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-black-status', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间观众记录
    roomVipLogList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-vip-log-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    roomVipLogExcel(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-vip-log-excel', data, true).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间聊天记录
    roomChatLogList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-chat-log-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    roomChatLogExcel(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-chat-log-excel', data, true).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间助理记录
    roomAdminList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-admin-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间助理操作
    roomAdminForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-admin-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间助理删除
    roomAdminDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-admin-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //快捷语列表
    roomWordsList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-words-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //快捷语操作
    roomWordsForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-words-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //用快捷语删除
    roomWordsDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-words-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}