<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-form ref="searchForm" :model="searchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="searchForm.key"
                    style="width: 180px"
                    placeholder="直播间名称"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="begin_at">
                  <el-date-picker
                    size="small"
                    style="width: 140px"
                    v-model="searchForm.begin_at"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="直播开始日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="end_at">
                  <el-date-picker
                    size="small"
                    style="width: 140px"
                    v-model="searchForm.end_at"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="直播结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetSearchForm()"
                  >重置</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button
                  size="small"
                  :loading="excelLoading"
                  @click="handleExcel"
                  >导出</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            row-key="id"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="listData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column label="直播间头像" width="110">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.web_vip_face"
                  fit="contain"
                  v-if="scope.row.web_face_url"
                ></el-image>
                <el-image
                  style="width: 100px; height: 100px"
                  src="/images/image-empty.png"
                  fit="contain"
                  v-else
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="直播间名称" min-width="300">
            </el-table-column>
            <el-table-column label="主播">
              <template slot-scope="scope">
                {{scope.row.vip?scope.row.vip.name:'无'}}
                <!-- <el-row type="flex" align="middle" v-if="scope.row.vip">
                  <el-col style="width: auto">
                    <el-avatar
                      :src="scope.row.vip.face_url"
                      :size="40"
                    ></el-avatar>
                  </el-col>
                  <el-col style="width: auto; padding-left: 10px">
                    {{ scope.row.vip.name }}
                  </el-col>
                </el-row>
                <div v-else>无</div> -->
              </template>
            </el-table-column>
            <el-table-column prop="man_num" label="实际人数" width="100">
            </el-table-column>
            <el-table-column prop="all_time" label="总观看时长" width="150">
            </el-table-column>
            <el-table-column prop="goods_num" label="点赞数" width="100">
            </el-table-column>
            <el-table-column label="直播状态" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 1">直播中</div>
                <div v-if="scope.row.status == 2">已结束</div>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="开始时间" width="140">
            </el-table-column>
            <el-table-column prop="end_at" label="结束时间" width="140">
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="240"
            >
              <template slot-scope="scope">
                <el-button
                  @click="manList(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-user"
                  size="small"
                >
                  观众列表
                </el-button>
                <el-button
                  @click="chatList(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-document"
                  size="small"
                >
                  聊天记录
                </el-button>
                <el-button
                  @click="deleteItem(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleCurrentChange"
              :total="pageConfig.counts"
              :page-size="pageConfig.pageSize"
              :current-page="pageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="观众列表"
      size="80%"
      :visible.sync="vipDrawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <div class="page_search">
          <el-form ref="vipSearchForm" :model="vipSearchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="vipSearchForm.key"
                    style="width: 180px"
                    placeholder="姓名"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleVipSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetVipSearchForm()"
                  >重置</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button
                  size="small"
                  :loading="excelLoading"
                  @click="handleVipExcel"
                  >导出</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            row-key="id"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="vipListData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-avatar :src="scope.row.face_url" :size="40"></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="vip_type" label="类型" width="80">
              <template slot-scope="scope">
                <div v-if="scope.row.vip_type == 2">观众</div>
                <div v-if="scope.row.vip_type == 3">助理</div>
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="vip_name"> </el-table-column>
            <el-table-column label="分组" prop="group_name"> </el-table-column>
            <el-table-column prop="all_num" label="进出次数" width="100">
            </el-table-column>
            <el-table-column prop="all_time" label="观看时长" width="150">
            </el-table-column>
            <el-table-column prop="ip" label="IP" width="150">
            </el-table-column>
            <el-table-column prop="address" label="地区" min-width="150">
            </el-table-column>
            <el-table-column prop="shield" label="屏蔽" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.shield == 1">是</div>
                <div v-if="scope.row.shield == 0">否</div>
              </template>
            </el-table-column>
            <el-table-column prop="black" label="黑名单" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.black == 1">是</div>
                <div v-if="scope.row.black == 0">否</div>
              </template>
            </el-table-column>
            <el-table-column prop="speak" label="禁言" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.speak == 1">是</div>
                <div v-if="scope.row.speak == 0">否</div>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="创建时间" width="140">
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleVipCurrentChange"
              :total="vipPageConfig.counts"
              :page-size="vipPageConfig.pageSize"
              :current-page="vipPageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title="聊天记录"
      size="80%"
      :visible.sync="chatDrawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <div class="page_search">
          <el-form ref="chatSearchForm" :model="chatSearchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="chatSearchForm.key"
                    style="width: 180px"
                    placeholder="姓名"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleChatSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetChatSearchForm()"
                  >重置</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button
                  size="small"
                  :loading="excelLoading"
                  @click="handleChatExcel"
                  >导出</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            row-key="id"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="chatListData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-avatar :src="scope.row.face_url" :size="40"></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="vip_type" label="类型" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.vip_type == 1">主播</div>
                <div v-if="scope.row.vip_type == 2">观众</div>
                <div v-if="scope.row.vip_type == 3">
                  {{ scope.row.ai_id == 0 ? "助理" : (scope.row.vip_name+"的马甲") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="name" width="150">
            </el-table-column>
            <el-table-column label="分组" prop="group_name" width="150"> </el-table-column>
            <el-table-column prop="msg" label="内容"> </el-table-column>
            <el-table-column prop="created_at" label="发言时间" width="140">
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="75"
            >
              <template slot-scope="scope">
                <el-button
                  @click="deleteChatItem(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleChatCurrentChange"
              :total="chatPageConfig.counts"
              :page-size="chatPageConfig.pageSize"
              :current-page="chatPageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import roomApi from "@/api/room";
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      vipDrawer: false,
      chatDrawer: false,
      excelLoading: false,
      searchForm: {
        key: "",
        begin_at: "",
        end_at: "",
      },
      model: null,
      listData: [],
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      tableHeight: 500,
      vipSearchForm: {
        room_log_id: 0,
        key: "",
      },
      vipListData: [],
      vipPageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      chatSearchForm: {
        room_log_id: 0,
        key: "",
      },
      chatListData: [],
      chatPageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
    };
  },
  created() {},
  mounted() {
    onTableHeight(this);
    this.getList();
  },
  methods: {
    //查询
    handleSearch() {
      this.getList();
    },
    resetSearchForm() {
      if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
      this.getList();
    },
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      roomApi
        .roomLogList({
          ...this.searchForm,
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.getList(val);
    },
    deleteItem(row) {
      this.$confirm("确定是否删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          roomApi
            .roomLogDelete({
              id: row.id,
            })
            .then((res) => {
              message.close();
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getList();
            })
            .catch((res) => {
              message.close();
            });
        })
        .catch(() => {});
    },
    handleExcel() {
      let message = this.$message({
        message: "请求中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      this.excelLoading = true;
      roomApi
        .roomLogExcel(this.searchForm)
        .then((res) => {
          const link = document.createElement("a");
          const blob = new Blob([res], { type: "application/vnd.ms-excel" }); //设置文件流
          let url = URL.createObjectURL(blob);
          link.style.display = "none";
          link.href = url;
          link.download = "直播记录";
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((res) => {})
        .finally(() => {
          message.close();
          this.excelLoading = false;
        });
    },
    manList(row) {
      this.vipSearchForm = {
        room_log_id: row.id,
        key: "",
      };
      this.vipListData=[];
      this.vipDrawer = true;
      this.getVipList();
    },
    handleVipSearch() {
      this.getVipList();
    },
    resetVipSearchForm() {
      this.vipSearchForm.key = "";
      this.getVipList();
    },
    handleVipCurrentChange(val) {
      this.getVipList(val);
    },
    //列表
    getVipList(pageIndex = 1) {
      this.loading = true;
      roomApi
        .roomVipLogList({
          ...this.vipSearchForm,
          page_size: this.vipPageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.vipListData = res.list;
          this.vipPageConfig.pageIndex = res.page.page_index;
          this.vipPageConfig.counts = res.page.counts;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    handleVipExcel() {
      let message = this.$message({
        message: "请求中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      this.excelLoading = true;
      roomApi
        .roomVipLogExcel(this.vipSearchForm)
        .then((res) => {
          const link = document.createElement("a");
          const blob = new Blob([res], { type: "application/vnd.ms-excel" }); //设置文件流
          let url = URL.createObjectURL(blob);
          link.style.display = "none";
          link.href = url;
          link.download = "观众列表";
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((res) => {})
        .finally(() => {
          message.close();
          this.excelLoading = false;
        });
    },
    chatList(row) {
      this.chatSearchForm = {
        room_log_id: row.id,
        key: "",
      };
      this.chatListData=[];
      this.chatDrawer = true;
      this.getChatList();
    },
    handleChatSearch() {
      this.getChatList();
    },
    resetChatSearchForm() {
      this.chatSearchForm.key = "";
      this.getChatList();
    },
    handleChatCurrentChange(val) {
      this.getChatList(val);
    },
    //
    getChatList(pageIndex = 1) {
      this.loading = true;
      roomApi
        .roomChatLogList({
          ...this.chatSearchForm,
          page_size: this.chatPageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.chatListData = res.list;
          this.chatPageConfig.pageIndex = res.page.page_index;
          this.chatPageConfig.counts = res.page.counts;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    handleChatExcel() {
      let message = this.$message({
        message: "请求中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      this.excelLoading = true;
      roomApi
        .roomChatLogExcel(this.chatSearchForm)
        .then((res) => {
          const link = document.createElement("a");
          const blob = new Blob([res], { type: "application/vnd.ms-excel" }); //设置文件流
          let url = URL.createObjectURL(blob);
          link.style.display = "none";
          link.href = url;
          link.download = "聊天记录";
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((res) => {})
        .finally(() => {
          message.close();
          this.excelLoading = false;
        });
    },
    deleteChatItem(row){
      this.$confirm("确定是否删除该记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          roomApi
            .roomChatLogDelete({
              id: row.id,
            })
            .then((res) => {
              message.close();
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getChatList(this.chatPageConfig.pageIndex);
            })
            .catch((res) => {
              message.close();
            });
        })
        .catch(() => {});
    }
  },
};
</script>
<style scope></style>
  